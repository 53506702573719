import { Link } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context";
import styles from "./Footer.module.css";

const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div className={styles.footer} style={{ backgroundImage: `url("${localStorage.getItem("apiUrl")}imagens/large/footer_background.webp")` }}>
            <div className="container">
                <div className="row">
                    <div className="col-3">
                        <div>
                            <Link to="/" title="Home"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/logo.svg`} alt="Ark'tetus" /></Link>
                            <div className={styles.sociais}>
                                {resumo?.configuracoes?.facebook?.length > 1 &&
                                    <a href={resumo.configuracoes.facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                                        <img src={`${localStorage.getItem("apiUrl")}imagens/medium/facebook.svg`} alt="Facebook" />
                                    </a>
                                }
                                {resumo?.configuracoes?.instagram?.length > 1 &&
                                    <a href={resumo.configuracoes.instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                        <img src={`${localStorage.getItem("apiUrl")}imagens/medium/instagram.svg`} alt="Instagram" />
                                    </a>
                                }
                                {resumo?.configuracoes?.linkedin?.length > 1 &&
                                    <a href={resumo.configuracoes.linkedin} target="_blank" rel="noopener noreferrer" title="LinkedIn">
                                        <img src={`${localStorage.getItem("apiUrl")}imagens/medium/linkedin.svg`} alt="LinkedIn" />
                                    </a>
                                }
                                {resumo?.configuracoes?.youtube?.length > 1 &&
                                    <a href={resumo.configuracoes.youtube} target="_blank" rel="noopener noreferrer" title="YouTube">
                                        <img src={`${localStorage.getItem("apiUrl")}imagens/medium/youtube.svg`} alt="YouTube" />
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="offset-1 col-2">
                        <div className={styles.menu_items}>
                            <Link to="/" title="Home">Home</Link>
                            <Link to="/empresa" title="Sobre nós">Sobre nós</Link>
                            <Link to="/produtos" title="Produtos">Produtos</Link>
                            <Link to="/blog" title="Blog">Blog</Link>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className={styles.menu_items}>
                            <Link to="/contato" title="Fale conosco">Fale conosco</Link>
                            {resumo?.catalogos && resumo?.catalogos[0] ? <a href={resumo.catalogos[0].arquivo} title="Baixe nosso catálogo" target={"_blank"} rel="noopener noreferrer">Baixe nosso catálogo</a> : ""}
                            <Link to="/documentos" title="Documentos">Documentos</Link>
                            <Link to="/politica-de-privacidade" title="Política de privacidade">Política de privacidade</Link>
                            <Link to="/termos-de-uso" title="Termos de uso">Termos de uso</Link>
                        </div>
                    </div>
                    <div className="col-3">
                        <div>
                            <h4 className="txt-white mb-4">Contato</h4>
                            <div>
                                <p className="txt-white">
                                    <span className="txt-bold">Telefone: </span> <a className="txt-white txt-underline ml-2" href={`tel:55${resumo?.configuracoes?.telefone.replace(/\D/g, '')}`} title="Telefone">+55 {resumo?.configuracoes?.telefone}</a><br />
                                </p>
                                <div className={styles.whatsapp}>
                                    <p className="txt-white">
                                        <span className="txt-bold">WhatsApp: </span> <a className={`txt-white txt-underline ml-2 ${styles.whatsapp}`} href={`https://wa.me/55${resumo?.configuracoes?.whatsapp.replace(/\D/g, '')}`} title="WhatsApp">
                                            <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/whatsapp.svg`} alt="WhatsApp" />
                                            +55 {resumo?.configuracoes?.whatsapp}</a>
                                    </p>
                                </div>
                                <p className="txt-white">
                                    <span className="txt-bold">E-mail: </span> <a className="txt-white txt-underline ml-2" href={`mailto:${resumo?.configuracoes?.email}`} title="E-mail">{resumo?.configuracoes?.email}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={`d-flex justify-space-between align-center ${styles.ending}`}>
                    <p className="txt-white">Ark'Tetus{resumo?.configuracoes?.cnpj?.length > 5 ? ` - CNPJ: ${resumo?.configuracoes?.cnpj}` : ""} - Todos os direitos reservados © {new Date().getFullYear()}</p>
                    <a className="txt-white" href="https://fw2propaganda.com.br" title="Desenvolvido por Gustavo Barbosa">FW2 Propaganda</a>
                </div>
            </div>
        </div >
    )
}

export default Footer;