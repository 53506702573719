import { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import Context from "../../Context";
import styles from "./Header.module.css";

const Header = () => {
    const { menuOpen, setMenuOpen, resumo } = useContext(Context);

    return (
        <div className={`${styles.header} ${window.location.pathname === "/" ? styles.transparent : ""}`}>
            <div className="container">
                <div className={styles.flex}>
                    <div className="logo">
                        <Link to="/" title="Home"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/logo.svg`} alt="Ark'tetus" /></Link>
                    </div>
                    <div className={styles.toggleMenu}>
                        <img onClick={() => setMenuOpen(!menuOpen)} src={`${localStorage.getItem('apiUrl')}imagens/large/${menuOpen ? "close" : "menu"}.svg`} alt="menu" />
                    </div>
                    <div className={`${styles.menu} ${menuOpen ? styles.menuOpen : ""}`}>
                        <Link to="/" title="Home">Home</Link>
                        <Link to="/empresa" title="Empresa">Empresa</Link>
                        <Link to="/produtos" title="Produtos">Produtos</Link>
                        {resumo.posts && resumo.posts[0] && <Link to="/blog" title="Blog">Blog</Link>}
                        <a className={styles.whatsapp} href={`https://wa.me/55${resumo.configuracoes.whatsapp.replace(/\D/g, '')}`} target="_blank" rel="noopener noreferrer">
                            <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/whatsapp.svg`} alt="WhatsApp" />
                            +55 {resumo.configuracoes.whatsapp}
                        </a>
                        <Link className={`btn txt-primary-contrast ${window.location.pathname === "/" ? styles.btnBorder : styles.btnFill}`} to="/contato" title="Solicite um orçamento">Solicite um orçamento</Link>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default withRouter(Header);



